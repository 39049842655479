import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import {Controller} from "react-hook-form";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

export default function WaiversQuestions(props) {

    const [waiverDialogIsOpen, setWaiverDialogIsOpen] = React.useState(false);
    const [photoReleaseDialogIsOpen, setPhotoReleaseDialogIsOpen] = React.useState(false);
    const [medicalClearanceDialogIsOpen, setMedicalClearanceDialogIsOpen] = React.useState(false);
    const [trainingFloorPolicyDialogIsOpen, setTrainingFloorPolicyDialogIsOpen] = React.useState(false);

    const openWaiverDialog = () => {
        setWaiverDialogIsOpen(true);
    };

    const closeWaiverDialog = () => {
        setWaiverDialogIsOpen(false);
    };

    const openPhotoReleaseDialog = () => {
        setPhotoReleaseDialogIsOpen(true);
    };

    const closePhotoReleaseDialog = () => {
        setPhotoReleaseDialogIsOpen(false);
    };

    const openMedicalClearanceDialog = () => {
        setMedicalClearanceDialogIsOpen(true);
    };

    const closeMedicalClearanceDialog = () => {
        setMedicalClearanceDialogIsOpen(false);
    };

    const openTrainingFloorPolicyDialog = () => {
        setTrainingFloorPolicyDialogIsOpen(true);
    };

    const closeTrainingFloorPolicyDialog = () => {
        setTrainingFloorPolicyDialogIsOpen(false);
    };

    return (
        <div>
            <Grid container spacing={3} justify="flex-start" style={{paddingTop: 20}}>

                <Grid item xs={12}>
                    <Typography component="div" variant="h5" color="secondary">Waivers</Typography>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth component="fieldset" error={!!props.errors.waiverReleaseSigned}>
                        <FormLabel component="legend">Waiver Release and Assumption of Risk Form</FormLabel>
                            <Box mt={1}>
                                <Link href="#" color="secondary" onClick={openWaiverDialog}>
                                    Click here to read details
                                </Link>
                            </Box>
                            <FormControlLabel
                                control={<Checkbox name="waiverReleaseSigned" />}
                                label="I Agree"
                                inputRef={props.register({required: true})}
                            />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl error={!!props.errors.testimonialAndPhotoReleaseSigned} component="fieldset">
                        <FormLabel component="legend">Testimonial and Photo Release Form</FormLabel>
                        <Box mt={1}>
                            <Link href="#" color="secondary" onClick={openPhotoReleaseDialog} style={{paddingTop: 5}}>
                                Click here to read details
                            </Link>
                        </Box>
                        <Controller
                            as={<RadioGroup
                                row
                                aria-label="testimonialAndPhotoReleaseSigned"
                            >
                                <FormControlLabel value={"I Agree"} control={<Radio />} label="I Agree" />
                                <FormControlLabel value={"I Do Not Agree"} control={<Radio />} label="I Do Not Agree" />
                            </RadioGroup>}

                            name="testimonialAndPhotoReleaseSigned"
                            defaultValue={""}
                            control={props.control}
                            rules={{ required: true }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth component="fieldset" error={!!props.errors.medicalClearanceSigned}>
                        <FormLabel component="legend">Valemee Medical Clearance</FormLabel>
                        <Box mt={1}>
                            <Link href="#" color="secondary" onClick={openMedicalClearanceDialog} style={{paddingTop: 5}}>
                                Click here to read details
                            </Link>
                        </Box>
                        <FormControlLabel
                            control={<Checkbox name="medicalClearanceSigned" />}
                            label="I Agree"
                            inputRef={props.register({required: true})}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth component="fieldset" error={!!props.errors.trainingFloorPolicySigned}>
                        <FormLabel component="legend">Training Floor Policy</FormLabel>
                        <Box mt={1}>
                            <Link href="#" color="secondary" onClick={openTrainingFloorPolicyDialog} style={{paddingTop: 5}}>
                                Click here to read details
                            </Link>
                        </Box>
                        <FormControlLabel
                            control={<Checkbox name="trainingFloorPolicySigned" />}
                            label="I Agree"
                            inputRef={props.register({required: true})}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Dialog
                open={waiverDialogIsOpen}
                aria-labelledby="waiver-dialog-title"
                aria-describedby="waiver-dialog-description"
            >
                <DialogTitle id="waiver-dialog-title">Waiver Release and Assumption of Risk Form</DialogTitle>
                <DialogContent>
                    <DialogContentText id="waiver-dialog-description">

                        <Box mb={2}>
                            You, the undersigned Participant, do, in consideration of the mutual promises contained herein and
                            other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged,
                            and your participation in a fitness program provided to you by your chosen Personal Trainer identified
                            below, (“Trainer”), and the fitness/training facility, Valemee, which may include, but may not be
                            limited to, resistance training and aerobic or cardiovascular exercise. And, in consideration of
                            Trainer’s agreement to instruct and train you, hereby release, discharge and hold harmless Trainer
                            and respective agents, heirs, assigns, contractors and employees, and Valemee, from any and all
                            claims, demands, damages and causes of action, present or future, arising out of or connected with
                            your participation in this or any exercise program including any injuries resulting therefrom.
                        </Box>

                        <Box mb={2}>
                            THIS WAIVER AND RELEASE OF LIABILITY INCLUDES, WITHOUT LIMITATION, INJURIES WHICH MAY OCCUR AS A
                            RESULT OF (1) EQUIPMENT BELONGING TO TRAINER, Valemee OR TO YOU THAT MAY MALFUNCTION OR BREAK;
                            (2) ANY SLIP, FALL, DROPPING OF EQUIPMENT; AND/OR (3) NEGLIGENT INSTRUCTION OR SUPERVISION.
                        </Box>

                        <Box mb={2}>
                            As a Participant, you have been provided information and understand and you aware that any exercise
                            program, whether or not requiring the use of exercise equipment, is a potentially hazardous activity.
                            You also have been informed of, understand and are aware that any exercise and/or fitness activity
                            involves a risk of injury, as well as abnormal changes in blood pressure, fainting, and a remote risk
                            of heart attack, stroke, other serious disability or death, and you are voluntarily participating in
                            these activities and using equipment and machinery with full knowledge, understanding and appreciation
                            of potential risk involved. You hereby agree to expressly assume and accept any and all risk of injury,
                            regardless of severity, or death. You have been advised that an examination by a physician should be
                            obtained prior to commencing a fitness and/or exercise program, or initiating a substantial change
                            in the amount of regular physical activity performed.
                        </Box>

                        <Box mb={2}>
                            If you have chosen not to obtain a physician’s consent prior to beginning this fitness program
                            with Trainer, you hereby agree that you are doing so solely at your own risk. You acknowledge and
                            agree that you assume the risk associated with any and all fitness related activities and/or exercises
                            in which you may participate.
                        </Box>

                        <Box mb={2}>
                            YOU ACKNOWLEDGE THAT YOU HAVE THOROUGHLY READ THIS FORM IN ITS ENTIRETY AND FULLY UNDERSTAND THAT
                            IT IS A RELEASE OF LIABILITY. BY SIGNING THIS DOCUMENT, YOU ARE WAIVING ANY RIGHT YOU OR YOUR
                            SUCCESSORS MIGHT HAVE TO BRING A LEGAL ACTION OR ASSERT A CLAIM AGAINST THE TRAINER OR Valemee
                            OR THAT OF EMPLOYEES, AGENTS OR CONTRACTORS.
                        </Box>

                        <Box mb={2}>
                            This form is an important legal document. It is important to read and understand this document
                            completely. If you do not understand any part of this document, it is your responsibility to ask
                            for clarification prior to authorizing.
                        </Box>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeWaiverDialog} color="secondary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={photoReleaseDialogIsOpen}
                aria-labelledby="photo-release-dialog-title"
                aria-describedby="photo-release-dialog-description"
            >
                <DialogTitle id="photo-release-dialog-title">Testimonial and Photo Release Form</DialogTitle>
                <DialogContent>
                    <DialogContentText id="photo-release-dialog-description">
                        <Box mb={2}>
                            I, the undersigned, hereby grant to Valemee (“Trainer”) and its agents the right to use my name, biographical
                            information, photographs, images, story and/or testimonial, in whole or in part, and without restriction as to changes
                            or alterations. The rights granted herein shall extend in perpetuity, unless revoked in writing to Trainer by me,
                            throughout the world and for any purpose whatsoever, including without limitation for marketing and advertising
                            purposes of Trainer, and in any and all media, including without limitation Trainer’s website. I acknowledge that
                            Trainer has no obligation to return any photographs or images to me.
                        </Box>

                        <Box mb={2}>
                            I hereby RELEASE, WAIVE and FOREVER DISCHARGE any and all claims arising out of, or in connection with, such use
                            by Trainer, including without limitation any and all claims for libel or invasion or privacy. I hereby warrant and
                            represent that I am at least 18 years of age and have the right to contract in my own name. I have read the above
                            Release and am fully familiar with the contents thereof. This Release contains the entire agreement between the
                            parties hereto as to the subject matter contained herein.
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePhotoReleaseDialog} color="secondary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={medicalClearanceDialogIsOpen}
                aria-labelledby="medical-clearance-dialog-title"
                aria-describedby="medical-clearance-dialog-description"
            >
                <DialogTitle id="medical-clearance-dialog-title">Valemee Medical Clearance</DialogTitle>
                <DialogContent>
                    <DialogContentText id="medical-clearance-dialog-description">

                        <Box mb={2}>
                            Participant desires to enroll in a Valemee fitness training program with Peggy Mills referred to
                            as "Trainer” and owner of Valemee.
                        </Box>

                        <Box mb={2}>
                            Participant understands that the training program will require some strenuous activities, and
                            such activities involve a risk of possible injury.
                        </Box>

                        <Box mb={2}>
                            Participant has, within the last six months, undergone a medical examination by a physician that
                            revealed no condition that would indicate likelihood that physical exercise would endanger
                            Participant’s health.
                        </Box>

                        <Box mb={2}>
                            Participant hereby agrees to assume the risk of injury, and to release and hold harmless Trainer
                            and other Valemee staff and volunteers acting under Trainer’s supervision from any and all liability,
                            claims, or damages for any physical injury that might occur in the course of the fitness training.
                        </Box>

                        <Box mb={2}>
                            Participant has current and adequate health insurance to cover expenses associated with any injury,
                            and authorizes Trainer in the event of medical emergency (1) to obtain urgently needed medical
                            treatment from a licensed medical provider, and (2) to notify immediately the Medical Contact
                            Person identified during this enrollment process.
                        </Box>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeMedicalClearanceDialog} color="secondary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={trainingFloorPolicyDialogIsOpen}
                aria-labelledby="training-floor-policy-dialog-title"
                aria-describedby="training-floor-policy-dialog-description"
            >
                <DialogTitle id="training-floor-policy-dialog-title">Training Floor Policy</DialogTitle>
                <DialogContent>
                    <DialogContentText id="training-floor-policy-dialog-description">

                        <Box mb={2}>
                            Valemee management has established a variety of policies and procedures to ensure that clients
                            have a safe, pleasant and fun experience while training with us. Please review the following policies.
                        </Box>

                        <Box mb={4}>
                            <Typography component="div" variant="h5" color="secondary">Fitness Attire</Typography>
                            <Typography component="div">
                                <ul>
                                    <li>
                                        Tennis shoes, socks, athletic pants/shorts, and t-shirts are considered appropriate attire.
                                    </li>
                                    <li>
                                        Shirts should cover full chest, back and lower torso area (no sport bras only).
                                    </li>
                                    <li>
                                        Jeans or clothing with zippers is not recommended. Sandals are NOT permitted. No bare feet around weights.
                                    </li>
                                    <li>
                                        If proper shoes and/or clothing is not worn, the trainers can change the workout plan to use alternative equipment at their discretion to ensure the safety of the client.
                                    </li>
                                    <li>
                                        During winter months and rainy days, please bring a dry pair of shoes.
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mb={4}>
                            <Typography component="div" variant="h5" color="secondary">Health and Safety</Typography>
                            <Typography component="div">
                                <ul>
                                    <li>
                                        Parents must remain in the waiting area during training sessions.
                                    </li>
                                    <li>
                                        At times, it may be necessary to end a session or use calming activities for the benefit of a client.
                                    </li>
                                    <li>
                                        The primary focus of each trainer is to provide quality instruction for each client.
                                        Therefore, if managing behaviors becomes the primary need during a session, the provider or parent
                                        present must be responsible to oversee and manage behaviors or to end the session if safety becomes
                                        a concern.
                                    </li>
                                    <li>
                                        In group sessions, if any client is displaying behaviors that are aggressive in nature, unsafe or
                                        disturbing to others in the group, the parent or caregiver may be asked to take the client to a quiet
                                        area to ensure the safety of all members or participants. Collaboration between the Valemee instructor
                                        and the parent or caregiver may result in removing the client from the session or the gym for that session.
                                    </li>
                                    <li>
                                        If you have the flu, a cold, or any other contagious illness, please do not train in the center.
                                        Exposure to contagious illnesses puts you and all other clients at risk.  Illness causes an individual's
                                        system to become weaker and the likelihood for injury increases significantly when training under these
                                        conditions.
                                    </li>
                                    <li>
                                        Please be courteous of others and use the supplies provided in the areas that you use.
                                    </li>
                                    <li>
                                        All clients will be encouraged to wash their hands upon leaving the gym.
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mb={4}>
                            <Typography component="div" variant="h5" color="secondary">Facility Procedures</Typography>
                            <Typography component="div">
                                <ul>
                                    <li>
                                        Clients requiring adult supervision must have an adult with them at all times during open gym.
                                        Anyone under the age of 18 must have an adult in the gym during all workouts unless there are
                                        two Valemee team members present.  At no time will a Valemee trainer work one on one with someone
                                        under the age of 18 without a second adult present.
                                    </li>
                                    <li>
                                        It is important to remember that Valemee is a fitness center. Children, clients or others accompanying a
                                        family to the gym will not be permitted to have unrestricted access to equipment or areas of the gym
                                        without permission and must sign a waiver to use the facility.
                                    </li>
                                    <li>
                                        Clients will remain in the specified area with a Valemee trainer to be respectful of the other trainers
                                        and clients who may be working in adjacent areas.
                                    </li>
                                    <li>
                                        Please do not slam or drop the weights.  If a client is unable to use weights without banging or
                                        dropping them after sufficient time of training for proper usage, weights and equipment may be removed
                                        from the area.  Use of select equipment will be at the discretion of the trainer.
                                    </li>
                                    <li>
                                        All lifters MUST use a spotter for safety reasons. Clients who are working independently must still have a
                                        spotter for barbell and free weight lifting.
                                    </li>
                                    <li>
                                        All clients will be asked to complete the “last rep of the workout”, i.e., help put equipment away at the
                                        end of their training session.
                                    </li>
                                    <li>
                                        Payment must be made at the time of service or prior to the beginning of a session.  Payment for sessions
                                        must be provided in advance.
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Box>
                            <Typography component="div" variant="h5" color="secondary">Cancellation Policy</Typography>
                            <Typography component="div" style={{paddingTop: 5}}>
                                Valemee clients must provide 24-hour notice of cancellation whenever possible.
                                If a session is cancelled within 2 hours of a scheduled appointment time or there is a no-show,
                                the client will be responsible for paying for the session. We understand the need to be flexible
                                when extenuating circumstances arise and we will work with you to accommodate for situations that
                                require rescheduling of missed sessions.  However, when we have paid staff here to accommodate
                                our clients, we will need to charge for no-shows.
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeTrainingFloorPolicyDialog} color="secondary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}