import {combineReducers} from 'redux'
import preWorkoutDataReducer from './preWorkoutDataSlice'
import exercisesReducer from './exercisesSlice'
import enrollmentDataReducer from './enrollmentDataSlice'
import enrollmentStepReducer from './enrollmentStepSlice'
import workoutStepReducer from './workoutStepSlice'
import navigateReducer from './navigateSlice'
import userReducer from './userSlice'
import clientReducer from './clientSlice'
import globalDialogReducer from './globalDialogSlice'

export default combineReducers({
    globalDialog: globalDialogReducer,
    user: userReducer,
    client: clientReducer,
    navigationStep: navigateReducer,
    enrollmentData: enrollmentDataReducer,
    enrollmentStep: enrollmentStepReducer,
    workoutStep: workoutStepReducer,
    preWorkoutData: preWorkoutDataReducer,
    exercises: exercisesReducer
})