import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {Controller} from "react-hook-form";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {TextField} from "@material-ui/core";

export default function OccupationalQuestions(props) {

    const watchRepetitiveMovementAnswer = props.watch('occupationHasExtendedPeriodsOfRepetitiveMovements', '');

    return (
        <Grid container spacing={3} justify="flex-start" style={{paddingTop: 20}}>

            <Grid item xs={12}>
                <Typography component="div" variant="h5" color="secondary">Occupational Questions</Typography>
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField error={!!props.errors.currentOccupation}
                               name="currentOccupation"
                               inputRef={props.register({required: true})}
                               id="currentOccupation"
                               label="* What is your current occupation?"
                               variant="outlined"
                               inputProps={{
                                   maxLength: 100
                               }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.occupationExtendedPeriodsOfSitting} component="fieldset">
                    <FormLabel component="legend">* Does your occupation require extended periods of sitting?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="occupationExtendedPeriodsOfSitting"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="occupationExtendedPeriodsOfSitting"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.occupationHasExtendedPeriodsOfRepetitiveMovements} component="fieldset">
                    <FormLabel component="legend">* Does your occupation require extended periods of repetitive movements?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="occupationHasExtendedPeriodsOfRepetitiveMovements"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}
                        name="occupationHasExtendedPeriodsOfRepetitiveMovements"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            {watchRepetitiveMovementAnswer === 'Yes' ?
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors.occupationRepetitiveMovementsDescription}
                                   name="occupationRepetitiveMovementsDescription"
                                   inputRef={props.register({required: true})}
                                   id="occupationRepetitiveMovementsDescription"
                                   label="* Description of Repetitive Movements"
                                   variant="outlined"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
            : ''}

            <Grid item xs={12}>
                <FormControl error={!!props.errors.occupationWearShoesWithHeel} component="fieldset">
                    <FormLabel component="legend">* Does your occupation require you to wear shoes with a heel (dress shoes)?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="occupationWearShoesWithHeel"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="occupationWearShoesWithHeel"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.occupationAnxietyOrMentalStress} component="fieldset">
                    <FormLabel component="legend">* Does your occupation cause you anxiety (mental stress)?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="occupationAnxietyOrMentalStress"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="occupationAnxietyOrMentalStress"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}