import {createSlice} from '@reduxjs/toolkit'

const globalDialogSlice = createSlice({
    name: 'globalDialog',
    initialState: {open: false},
    reducers: {
        openGlobalDialog(state, action) {
            const errorMessage = action.payload.errorMessage;
            const errorDetails = action.payload.errorDetails;
            return {open: true, errorMessage: errorMessage, errorDetails: errorDetails};
        },
        closeGlobalDialog() {
            return {open: false};
        }
    }
});

export const {openGlobalDialog, closeGlobalDialog} = globalDialogSlice.actions;

export default globalDialogSlice.reducer