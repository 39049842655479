import React, {useEffect} from 'react';

export default function Diagnoses(props) {

    const [diagnoses, setDiagnoses] = React.useState([]);

    useEffect(() => {
        console.log('useEffect fired for parsing diagnoses', JSON.stringify(props.physicalProfile));
        const diagnosesList = [];
        possibleDiagnoses.forEach(diagnosis => {
            if (props.physicalProfile[diagnosis.name] === 'Yes') {
                console.log('adding diagnosis of ' + diagnosis.name);
                diagnosesList.push(diagnosis.description);
            }
        });
        setDiagnoses(diagnosesList);
    }, []);

    const getDiagnosesText = () => {
        if (!diagnoses.length) {
            return "N/A";
        } else {
            return diagnoses.join(", ");
        }
    };

    return (
        <span>
            {getDiagnosesText()}
        </span>
    )

}

const possibleDiagnoses = [
    {
        "name": "autism",
        "description": "Autism"
    },
    {
        "name": "downSyndrome",
        "description": "Down Syndrome"
    },
    {
        "name": "praderWilli",
        "description": "Prader Willi"
    },
    {
        "name": "cp",
        "description": "CP"
    },
    {
        "name": "tbi",
        "description": "TBI"
    },
    {
        "name": "dd",
        "description": "DD"
    },
    {
        "name": "amputee",
        "description": "Amputee"
    },
    {
        "name": "spinaBifida",
        "description": "Spina Bifida"
    },
    {
        "name": "epilepsy",
        "description": "Epilepsy"
    },
    {
        "name": "depression",
        "description": "Depression"
    },
    {
        "name": "personalityDisorder",
        "description": "Personality Disorder"
    },
    {
        "name": "bipolarDisorder",
        "description": "Bipolar Disorder"
    },
    {
        "name": "muscularDystrophy",
        "description": "Muscular Dystrophy"
    },
    {
        "name": "rhumatoidArthritis",
        "description": "Rhumatoid Arthritis"
    },
    {
        "name": "adhd",
        "description": "ADHD"
    }
];