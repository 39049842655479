import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {
    setNavigationToClientAssessment, setNavigationToEnrollmentForm,
    setNavigationToGoalUpdater, setNavigationToLandingPage
} from "../../reducers/navigateSlice";
import {resetExercises} from "../../reducers/exercisesSlice";
import {removeClient, setClient} from "../../reducers/clientSlice";
import {resetEnrollmentStep} from "../../reducers/enrollmentStepSlice";
import {resetEnrollmentData} from "../../reducers/enrollmentDataSlice";
import Grid from "@material-ui/core/Grid";
import Workouts from "./Workouts";
import EquipmentOverview from "./EquipmentOverview";
import ClientReports from "./ClientReports";
import StartWorkoutButton from "./StartWorkoutButton";

export default function ClientDashboard() {

    const dispatch = useDispatch();

    const chosenClient = useSelector(store => store.client);

    useEffect(() => {
        console.log('useEffect fired that does nothing, todo, either add stuff here or remove');
    }, [dispatch]);

    const updateGoals = () => {
        // Clear any previous workout data
        dispatch(setClient(chosenClient));
        dispatch(setNavigationToGoalUpdater());
        ReactGA.event({
            category: 'Goal Editing',
            action: 'Start'
        });
    };

    const startAssessment = () => {
        dispatch(resetExercises());
        dispatch(setClient(chosenClient));
        dispatch(setNavigationToClientAssessment());
        ReactGA.event({
            category: 'Assessment',
            action: 'Start'
        });
    };

    const enrollClient = () => {
        if (isClientEnrolled()) {
            return;
        }

        dispatch(setClient(chosenClient));
        dispatch(resetEnrollmentStep());
        dispatch(resetEnrollmentData());
        dispatch(setNavigationToEnrollmentForm());
        ReactGA.event({
            category: 'Enrollment',
            action: 'Start'
        });
    };

    const isClientEnrolled = () => {
        return chosenClient && chosenClient.membershipType;
    };

    const goBackToLandingPage = () => {
        dispatch(removeClient());
        dispatch(setNavigationToLandingPage());
    };

    return (
        <Box m={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Card raised={true} style={{height: '100%'}}>
                        <CardContent>
                                <Typography gutterBottom component="div" variant="h4">Client: {chosenClient.name}</Typography>
                                {isClientEnrolled() ?
                                    <Typography component="div" variant="h6">Membership Type: {chosenClient.membershipType}</Typography>
                                    :
                                    <Box>
                                        <Typography component="span" variant="h6">Membership Type: </Typography>
                                        <Button variant="text" onClick={enrollClient}>Not Enrolled: Click Here to Enroll</Button>
                                    </Box>
                                }
                            <Typography component="div" style={{paddingTop: 10}}>
                                Choose any of the buttons below to begin that activity.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid container>
                                <Grid item>
                                    <Box m={1} onClick={startAssessment}>
                                        <Button type="submit" variant="contained" style={{backgroundColor: "white"}}>Perform An Assessment</Button>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box  m={1} onClick={updateGoals}>
                                        <Button type="submit" variant="contained" color="secondary">Add / Update Goals</Button>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box  m={1}>
                                        <StartWorkoutButton/>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box  m={1}>
                                        <ClientReports/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <EquipmentOverview raised={true}/>
                </Grid>

                <Grid item xs={12}>
                    <Workouts/>
                </Grid>

                <Grid container justifyContent="center" style={{paddingTop: 20, paddingBottom: 20}}>
                    <Box p={2} mx="auto">
                        <Button variant="contained" color="secondary" onClick={goBackToLandingPage}>Finished With Client</Button>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
}