import React from 'react';
import InputMask from "react-input-mask";
import {TextField} from "@material-ui/core";

export default function PhoneNumberInput(props) {
    return (
        <div>
            <InputMask mask="1 (999) 999-9999">
                {inputProps =>
                    <TextField
                        {...inputProps}
                        fullWidth
                        error={!!props.errors[props.prefix + 'Phone']}
                        type="tel"
                        name={props.prefix + 'Phone'}
                        inputRef={props.register({required: props.phoneRequired, pattern: /1\s\(\d{3}\)\s\d{3}-\d{4}/})}
                        id={props.prefix + 'Phone'}
                        label={(props.phoneRequired ? '* ' : '') + 'Phone'}
                        defaultValue=''
                    />
                }
            </InputMask>
        </div>
    )
}