import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {
    setNavigationToClientDashboardPage,
} from "../../reducers/navigateSlice";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import {useForm} from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import {getClient} from "../../api/api";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LoggedInAs from "./LoggedInAs";
import {setClient} from "../../reducers/clientSlice";

export default function LandingPage() {

    const dispatch = useDispatch();
    const {register, errors, handleSubmit} = useForm();

    const [addClientDialogIsOpen, setAddClientDialogIsOpen] = React.useState(false);
    const [clientAddedConfirmationDialogIsOpen, setClientAddedConfirmationDialogIsOpen] = React.useState(false);
    const [requestInProgress, setRequestInProgress] = React.useState(false);
    const [clients, setClients] = React.useState([]);
    const [chosenClient, setChosenClient] = React.useState(null);
    const [mustChooseClientDialogIsOpen, setMustChooseClientDialogIsOpen] = React.useState(false);

    useEffect(() => {
        console.log('useEffect fired for loading clients');
        loadClients();
    }, [dispatch]);

    const loadClients = () => {
        getClient().get('/api/clients')
            .then((response) => {
                setClients(response.data);
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not retrieve clients.', errorDetails: error.message}));
            });
    };

    const openAddClientDialog = () => {
        setAddClientDialogIsOpen(true);
    };

    const closeAddClientDialog = () => {
        setAddClientDialogIsOpen(false);
    };

    const closeClientAddedConfirmationDialog = () => {
        setClientAddedConfirmationDialogIsOpen(false);
    };

    const goToClientDashboard = () => {
        if (chosenClient) {
            dispatch(setClient(chosenClient));
            dispatch(setNavigationToClientDashboardPage());
        } else {
            setMustChooseClientDialogIsOpen(true);
        }
    };

    const handleClientChange = (event, value) => {
        console.log('changing client to', JSON.stringify(value));
        ReactGA.event({
            category: 'Client',
            action: 'Chosen'
        });
        setChosenClient(value);
    };

    const handleMustChooseClientDialogClose = () => {
        setMustChooseClientDialogIsOpen(false);
    };

    const createNewClient = newClient => {
        console.log('result of new client form:', newClient);
        closeAddClientDialog();
        setRequestInProgress(true);
        getClient().post('/api/client', newClient)
            .then((response) => {
                loadClients();
                setClientAddedConfirmationDialogIsOpen(true);
                ReactGA.event({
                    category: 'Client',
                    action: 'Added'
                });
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    dispatch(openGlobalDialog({errorMessage: 'This client already exists.', errorDetails: ''}));
                } else {
                    dispatch(openGlobalDialog({errorDetails: error.message}));
                }
            })
            .then(() => {
                setRequestInProgress(false);
            });
    };

    return (
        <Container>
            <Box m={2}>
                <Typography component="div" variant="h5" color="secondary" align="center">
                    Valemee Workout Tracker
                </Typography>
            </Box>
            <Box pt={2} mx="auto" maxWidth={600}>
                <LoggedInAs />
            </Box>
            <Box pt={2} mx="auto" maxWidth={600}>
                <Card raised={true}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Add a Client
                        </Typography>
                        <Typography component="div">
                            If this is a new client, or an existing client who has already completed the enrollment process offline, choose Add Client to add them to the system.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Box mx="auto">
                            <Button variant="contained" color="secondary" onClick={openAddClientDialog}>Add Client</Button>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
            <Box pt={2} mx="auto" maxWidth={600}>
                <Card raised={true}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Start Working with a Client
                        </Typography>
                        <Box mt={2}>
                            <Autocomplete
                                id="client"
                                name="client"
                                value={chosenClient}
                                onChange={handleClientChange}
                                options={clients}
                                getOptionLabel={option => option.name + ' (' + option.email + ')'}
                                renderInput={params => <TextField {...params} label="Choose Client" variant="outlined" />}
                            />
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Box mx="auto" onClick={goToClientDashboard}>
                            <Button type="submit" variant="contained" color="primary">Go to Client Dashboard</Button>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
            <Box py={3} mx="auto" maxWidth={600}>
                <Card raised={true}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Training
                        </Typography>
                        <Typography component="div">
                            Click <a target='_blank' href='/videos/app-training.mp4'>here</a> to view the Tracker application training video.
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
            <Dialog
                open={mustChooseClientDialogIsOpen}
                onClose={handleMustChooseClientDialogClose}
                aria-labelledby="choose-client-alert-dialog-title"
                aria-describedby="choose-client-alert-dialog-description"
            >
                <DialogTitle id="choose-client-alert-dialog-title">Choose Client</DialogTitle>
                <DialogContent>
                    <DialogContentText id="choose-client-alert-dialog-description">
                        Please choose a client to continue.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleMustChooseClientDialogClose}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={addClientDialogIsOpen} onClose={closeAddClientDialog} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit(createNewClient)}>
                    <DialogTitle id="form-dialog-title">Add Client</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Complete the following information to add a user to the system.
                        </DialogContentText>

                        <FormControl fullWidth>
                            <TextField error={!!errors.firstName}
                                       name='firstName'
                                       type="text"
                                       inputRef={register({required: true})}
                                       id="firstName"
                                       label="* First Name"
                                       inputProps={{
                                           maxLength: 100
                                       }}
                            />
                        </FormControl>
                        <FormControl fullWidth style={{marginTop: 14}}>
                            <TextField error={!!errors.lastName}
                                       name='lastName'
                                       type="text"
                                       inputRef={register({required: true})}
                                       id="lastName"
                                       label="* Last Name"
                                       inputProps={{
                                           maxLength: 100
                                       }}
                            />
                        </FormControl>
                        <FormControl fullWidth style={{marginTop: 14}}>
                            <TextField error={!!errors.email}
                                       name='email'
                                       type="email"
                                       inputRef={register({required: true, pattern: /^\S+@\S+$/i})}
                                       id="email"
                                       label="* Email"
                                       inputProps={{
                                           maxLength: 100
                                       }}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeAddClientDialog} color="">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" disabled={requestInProgress}>
                            Add Client
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                open={clientAddedConfirmationDialogIsOpen}
                onClose={closeClientAddedConfirmationDialog}
                aria-labelledby="client-added-alert-dialog-title"
                aria-describedby="client-added-alert-dialog-description"
            >
                <DialogTitle id="client-added-alert-dialog-title">Client Added</DialogTitle>
                <DialogContent>
                    <DialogContentText id="client-added-alert-dialog-description">
                        This client has been added.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeClientAddedConfirmationDialog} color="secondary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}