import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";

export default function BehaviorProfile(props) {

    return (
                <Box pt={2} maxWidth={800} mx="auto">
                    <Grid container spacing={3} justify="flex-start">

                        <Grid item xs={12} style={{paddingTop: 40}}>
                            <Typography component="div" variant="h5" color="secondary">Behavior Profile</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField error={!!props.errors.impactingBehaviors}
                                           name="impactingBehaviors"
                                           type="text"
                                           multiline
                                           inputRef={props.register}
                                           id="impactingBehaviors"
                                           label="Impacting Behaviors"
                                           inputProps={{
                                               maxLength: 2500
                                           }}
                                />
                                <FormHelperText>Describe any behaviors which might impact participation in group activities.</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField error={!!props.errors.behaviorStrategies}
                                           name="behaviorStrategies"
                                           type="text"
                                           multiline
                                           inputRef={props.register}
                                           id="behaviorStrategies"
                                           label="Behavior Strategies"
                                           inputProps={{
                                               maxLength: 2500
                                           }}
                                />
                                <FormHelperText>Describe any behavioral strategies, communication needs, visual supports that you use which may be helpful.</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField error={!!props.errors.sensoryPreferences}
                                           name="sensoryPreferences"
                                           type="text"
                                           multiline
                                           inputRef={props.register}
                                           id="sensoryPreferences"
                                           label="Sensory Preferences"
                                           inputProps={{
                                               maxLength: 2500
                                           }}
                                />
                                <FormHelperText>List music/sensory preferences during workouts (Like quiet, like a certain type of music)?</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField error={!!props.errors.aggressiveBehaviors}
                                           name="aggressiveBehaviors"
                                           type="text"
                                           multiline
                                           inputRef={props.register}
                                           id="aggressiveBehaviors"
                                           label="Aggressive Behaviors"
                                           inputProps={{
                                               maxLength: 2500
                                           }}
                                />
                                <FormHelperText>Have you ever displayed aggressive behaviors toward self or others? If so what behaviors?</FormHelperText>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Box>
    )

}