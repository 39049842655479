import React from 'react';
import {Chip} from "@material-ui/core";



export default function Behavior(props) {

    const shouldDisplay = () => {
        // Most behaviors have a value of 'Yes' or 'No', so if the value is 'Yes', we want to display it.
        if (props.value === 'Yes') {
            return true;
        }

        // For "Other" behaviors, it's a write-in field, so if there's anything there, it gets shown,
        // and this is flagged with the rawValue property
        if (props.rawValue && props.value) {
            return true;
        }

        return false;
    }

    const determineLabel = () => {
        if (props.rawValue) {
            return props.label + props.value;
        } else {
            return props.label;
        }
    }


    return (
        <span>
            {shouldDisplay()  &&
                <Chip style={{marginRight: 4}} size="small" variant="outlined" label={determineLabel()} />
            }
        </span>
    );
}