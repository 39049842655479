import axios from 'axios';

function getClient() {

    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');

    return axios.create({
        auth: {
            username: username,
            password: password
        },
        timeout: 10000
    });
}

export { getClient };