import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import AssessmentExercises from "./AssessmentExercises";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";
import DiscardAssessmentButton from "./buttons/DiscardAssessmentButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {removeClient} from "../../reducers/clientSlice";
import {setNavigationToClientDashboardPage} from "../../reducers/navigateSlice";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {addExercise, removeExercise} from "../../reducers/exercisesSlice";
import {getClient} from "../../api/api";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";

export default function ClientAssessment() {

    const client = useSelector(store => store.client);
    const exercises = useSelector(store => store.exercises);

    const {register, errors} = useForm();

    const dispatch = useDispatch();

    const [completeAllExercisesDialogIsOpen, setCompleteAllExercisesDialogIsOpen] = React.useState(false);
    const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [weight, setWeight] = React.useState('');
    const [bmi, setBmi] = React.useState('');
    const [optionalExercise, setOptionalExercise] = React.useState('Push Up');
    const [requestInProgress, setRequestInProgress] = React.useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const handleWeightChange = (event) => {
        setWeight(event.target.value);
    };

    const handleBmiChange = (event) => {
        setBmi(event.target.value);
    };

    const handleOptionalExerciseChange = (event) => {
        console.log('changed fired');
        const newValue = event.target.value;
        if (newValue === 'Push Up') {
            removeExerciseFromAssessment('Chest Press');
            dispatch(addExercise({type: 'Assessment', name: 'Push Up'}));
        } else {
            removeExerciseFromAssessment('Push Up');
            dispatch(addExercise({type: 'Assessment', name: 'Chest Press'}));
        }
        setOptionalExercise(event.target.value);
    };

    const removeExerciseFromAssessment = (exerciseName) => {
        const exercise = exercises.find(e => e.name === exerciseName);
        if (exercise) {
            dispatch(removeExercise(exercise));
        }
    };

    const submitAssessment = () => {
        const unfinished = exercises.find(exercise => exercise.finished !== true);
        if (unfinished) {
            setCompleteAllExercisesDialogIsOpen(true);
        } else {
            const preAssessmentQuestions = {date: selectedDate, weight: weight, bmi: bmi};
            const assessment = {preAssessmentQuestions: preAssessmentQuestions, exercises : exercises, client: client};
            console.log('assessment data is: ', JSON.stringify(assessment));

            getClient().post('/api/assessment', assessment)
                .then((response) => {
                    console.log(response);
                    // Pop an alert dialog sating it's been saved
                    setConfirmationDialogIsOpen(true);
                    ReactGA.event({
                        category: 'Assessment',
                        action: 'Completed'
                    });
                })
                .catch((error) => {
                    dispatch(openGlobalDialog({errorDetails: error.message}));
                })
                .then(() => {
                    setRequestInProgress(false);
                });

        }
    };

    const handleCloseCompleteAllExercisesDialog = () => {
        setCompleteAllExercisesDialogIsOpen(false);
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogIsOpen(false);
        dispatch(setNavigationToClientDashboardPage());
    };

    return (
        <div>
            <Container>
                <Box p={2} mx="auto">
                    <Typography component="div" variant="h5" color="secondary" align="center">Assessment: {client.name}</Typography>
                </Box>
                <Box mb={5}>
                    <Container>
                        <Box pt={2} maxWidth={800} mx="auto">
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} sm={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            id="date-picker-dialog"
                                            name="date"
                                            inputRef={register({ required: true })}
                                            label="* Date of Assessment"
                                            format="MM/dd/yyyy"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth>
                                        <TextField error={!!errors.weight}
                                                   name="weight"
                                                   type="tel"
                                                   inputRef={register({required: true, pattern: /^[1-9][0-9]{1,2}$/})} // two or three digit number, first number non-zero
                                                   id="weight"
                                                   label="Weight (lbs)"
                                                   value={weight}
                                                   onChange={handleWeightChange}
                                                   inputProps={{
                                                       maxLength: 3
                                                   }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth>
                                        <TextField error={!!errors.bmi}
                                                   name="bmi"
                                                   type="tel"
                                                   inputRef={register({required: true, pattern: /^[1-9][0-9]{1}$/})} // two digit number, first digit non-zero
                                                   id="bmi"
                                                   label="BMI"
                                                   value={bmi}
                                                   onChange={handleBmiChange}
                                                   inputProps={{
                                                       maxLength: 2
                                                   }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Optional Exercise</FormLabel>
                                        <RadioGroup row aria-label="optionalExercise" value={optionalExercise} onChange={handleOptionalExerciseChange}>
                                            <FormControlLabel value={"Push Up"} control={<Radio />} label="Push Up" />
                                            <FormControlLabel value={"Chest Press"} control={<Radio />} label="Chest Press" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
                <AssessmentExercises/>
                <Grid container justify="center" style={{paddingTop: 40, paddingBottom: 20}}>
                    <Box component="span" m={0.5}>
                        <DiscardAssessmentButton/>
                    </Box>
                    <Box component="span" m={0.5}>
                        <Button variant="contained" color="primary" endIcon={<CheckIcon/>} onClick={submitAssessment} disabled={requestInProgress}>
                            Submit Assessment
                        </Button>
                    </Box>
                </Grid>
            </Container>
            <Dialog open={completeAllExercisesDialogIsOpen} aria-labelledby="complete-exercises-alert-dialog">
                <DialogTitle id="complete-exercises-alert-dialog">Complete Exercises</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please save all exercises before continuing.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCompleteAllExercisesDialog} variant="contained">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmationDialogIsOpen}
                aria-labelledby="assessment-submitted-alert-dialog-title"
                aria-describedby="assessment-submitted-alert-dialog-description"
            >
                <DialogTitle id="assessment-submitted-alert-dialog-title">{"Assessment Submitted"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="assessment-submitted-alert-dialog-description">
                        The assessment for {client.name} has been successfully submitted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmationDialogClose} color="secondary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

