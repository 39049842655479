import React, {useEffect} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {getClient} from "../../api/api";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";
import StartWorkoutButton from "./StartWorkoutButton";
import WorkoutDetails from "./WorkoutDetails";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

export default function Workouts() {

    const dispatch = useDispatch();

    const client = useSelector(store => store.client);

    const windowSize = 5;

    const [workoutOverviews, setWorkoutOverviews] = React.useState([]);
    const [visibleCount, setVisibleCount] = React.useState(windowSize);

    useEffect(() => {
        retrieveWorkoutOverviews();
    }, [dispatch]);

    const retrieveWorkoutOverviews = () => {
        getClient().get('/api/workouts/' + client.memberId + '/')
            .then((response) => {
                setWorkoutOverviews(response.data);
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not retrieve workouts.', errorDetails: error.message}));
            });
    };

    const showMore = () => {
        let newVisibleCount = visibleCount + windowSize;
        if (newVisibleCount > workoutOverviews.length) {
            newVisibleCount = workoutOverviews.length;
        }
        setVisibleCount(newVisibleCount);
    }

    const shouldShowMoreButtonBeVisible = () => {
        return visibleCount < workoutOverviews.length;
    }

    const getWorkoutHelpText = () => {
        if (workoutOverviews.length === 0) {
            return 'There are no workouts for this client.';
        }

        // At this point, there is at least one or more workouts.
        let text = client.firstName + ' has completed ' + workoutOverviews.length;
        if (workoutOverviews.length > 1) {
            text += " workouts.  Click on any workout to view its details.";
        } else {
            text += " workout.  Click on the workout to view its details.";
        }
        if (shouldShowMoreButtonBeVisible()) {
            text += " Click the 'Show More Workouts' button to view more of this client's workouts."
        }

        return text;
    }

    return (
        <Card raised={true} style={{height: '100%'}}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Workouts
                </Typography>
                <Typography component="div">
                    {getWorkoutHelpText()}
                </Typography>
                {workoutOverviews.map((workoutOverview, index) => {
                    if (index < visibleCount) {
                        return (<WorkoutDetails overview={workoutOverview}/>);
                    }
                })}
            </CardContent>
            <CardActions>
                <Box mx="auto">
                    { shouldShowMoreButtonBeVisible() &&
                        <Button variant="outlined" onClick={showMore} style={{marginRight: 4}}>Show More Workouts</Button>
                    }
                    <StartWorkoutButton/>
                </Box>
            </CardActions>
        </Card>
    );
}