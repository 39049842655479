import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {Controller} from "react-hook-form";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export default function ParQQuestionnaire(props) {

    return (
        <Grid container spacing={3} justify="flex-start" style={{paddingTop: 20}}>

            <Grid item xs={12}>
                <Typography component="div" variant="h5" color="secondary">PAR-Q Questionnaire</Typography>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.heartCondition} component="fieldset">
                    <FormLabel component="legend">* Has your doctor ever said that you have a heart condition and that you
                        should only perform physical activity recommended by a doctor?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="heartCondition"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="heartCondition"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.activityChestPain} component="fieldset">
                    <FormLabel component="legend">* Do you feel pain in your chest when you perform physical activity?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="activityChestPain"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="activityChestPain"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.nonActivityChestPain} component="fieldset">
                    <FormLabel component="legend">* In the past month, have you had chest pain when you were not performing any physical activity?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="nonActivityChestPain"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="nonActivityChestPain"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.lossOfBalance} component="fieldset">
                    <FormLabel component="legend">* Do you lose your balance because of dizziness or do you ever lose consciousness?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="lossOfBalance"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="lossOfBalance"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.boneOrJointProblem} component="fieldset">
                    <FormLabel component="legend">* Do you have a bone or joint problem that could be made worse by a change in your physical activity?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="boneOrJointProblem"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="boneOrJointProblem"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.medication} component="fieldset">
                    <FormLabel component="legend">* Is your doctor currently prescribing any medication for your blood pressure or for a heart condition?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="medication"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="medication"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.reasonNotToEngage} component="fieldset">
                    <FormLabel component="legend">* Do you know if any other reason why you should not engage in physical activity?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="reasonNotToEngage"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="reasonNotToEngage"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.exerciseHowOften} component="fieldset">
                    <FormLabel component="legend">* How many times per week do you currently exercise?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="exerciseHowOften"
                        >
                            <FormControlLabel value={"1"} control={<Radio />} label="One" />
                            <FormControlLabel value={"2"} control={<Radio />} label="Two" />
                            <FormControlLabel value={"3"} control={<Radio />} label="Three" />
                            <FormControlLabel value={"4"} control={<Radio />} label="Four" />
                            <FormControlLabel value={"More than 4"} control={<Radio />} label="More Than Four" />
                        </RadioGroup>}

                        name="exerciseHowOften"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}