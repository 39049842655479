import {createSlice} from '@reduxjs/toolkit'

const enrollmentDataSlice = createSlice({
    name: 'enrollmentData',
    initialState: {},
    reducers: {
        storeEnrollmentContactInfo(state, action) {
            console.log('current state', JSON.stringify(state));
            console.log('incoming enrollment client info data', JSON.stringify(action.payload));
            return {...state, clientInfo: action.payload};
        },
        storeEnrollmentProfiles(state, action) {
            console.log('current state', JSON.stringify(state));
            console.log('incoming enrollment profile data', JSON.stringify(action.payload));
            return {...state, physicalProfile: action.payload};
        },
        storeEnrollmentGoals(state, action) {
            console.log('current state', JSON.stringify(state));
            console.log('incoming goal data', JSON.stringify(action.payload));
            return {...state, goals: action.payload.goals};
        },
        storeEnrollmentParQQuestionnaire(state, action) {
            console.log('current state', JSON.stringify(state));
            console.log('incoming parq data', JSON.stringify(action.payload));
            return {...state, parQ: action.payload};
        },
        storeEnrollmentPhysicalActivityQuestionnaire(state, action) {
            console.log('current state', JSON.stringify(state));
            console.log('incoming physical activity data', JSON.stringify(action.payload));
            return {...state, physicalActivityQuestionnaire: action.payload};
        },
        resetEnrollmentData() {
            return  {};
        }
    }
});

export const {storeEnrollmentContactInfo, storeEnrollmentProfiles, storeEnrollmentGoals,
    storeEnrollmentParQQuestionnaire, storeEnrollmentPhysicalActivityQuestionnaire, resetEnrollmentData} = enrollmentDataSlice.actions;

export default enrollmentDataSlice.reducer