import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import ContactInfoSection from "./contact/ContactInfoSection";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import GoalsSection from "./goals/GoalsSection";
import {useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ProfileSection from "./profile/ProfileSection";
import ParQSection from "./parq/ParQSection";
import PhysicalActivitySection from "./physicalactivity/PhysicalActivitySection";
import WaiversSection from "./waivers/WaiversSection";

export default function EnrollmentForm() {

    const enrollmentStep = useSelector(store => store.enrollmentStep);

    const steps = ['Contact Info', 'Profiles', 'Goals', 'Par-Q Questionnaire', 'Physical Activity Readiness', 'Waivers'];

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [enrollmentStep]);

    return (
        <div>
            <Box p={2} mx="auto">
                <Typography component="div" variant="h5" color="secondary" align="center">Client Enrollment</Typography>
            </Box>
            <Stepper activeStep={enrollmentStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Container>
                <ContactInfoSection show={enrollmentStep === 0}/>
                <ProfileSection show={enrollmentStep === 1}/>
                <GoalsSection show={enrollmentStep === 2}/>
                <ParQSection show={enrollmentStep === 3}/>
                <PhysicalActivitySection show={enrollmentStep === 4}/>
                <WaiversSection show={enrollmentStep === 5}/>
            </Container>
        </div>
    )
}