import React from 'react';
import Behavior from "./Behavior";
import Label from "./Label";

export default function Behaviors(props) {

    const workout = props.workout;

    return (
        <div>
            <Label label="Behaviors: "/>
            <Behavior value={workout.behaviorsNone} label="None"/>
            <Behavior value={workout.behaviorsHandBiting} label="Hand Biting"/>
            <Behavior value={workout.behaviorsHeadShakeNo} label="Head Shake No"/>
            <Behavior value={workout.behaviorsDestructiveOfProperty} label="Destructive Of Property"/>
            <Behavior value={workout.behaviorsGrabbing} label="Grabbing"/>
            <Behavior value={workout.behaviorsAggressiveTowardsStaff} label="Aggressive Towards Staff"/>
            <Behavior rawValue={true} value={workout.behaviorsOther} label="Other: "/>
        </div>
    );
}