import React from 'react';
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {previousWorkoutStep} from "../../../reducers/workoutStepSlice";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export default function PreviousStepButton() {

    const dispatch = useDispatch();

    const handlePreviousStep = () => {
        dispatch(previousWorkoutStep());
    };

    return (
        <Button variant="contained" color="secondary" startIcon={<ArrowBackIosIcon/>} onClick={handlePreviousStep}>
            Back
        </Button>
    )
};