import React from 'react';
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

export default function WorkoutStepButtonDivider() {
    return (
        <Box mx="auto" maxWidth={800} p={3}>
            <Divider />
        </Box>
    )
}