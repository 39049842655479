import React from 'react';
import ReactGA from 'react-ga';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationToLoginPage} from "../../reducers/navigateSlice";

export default function LoggedInAs() {

    const dispatch = useDispatch();

    const user = useSelector(store => store.user);

    const handleLogout = () => {
        ReactGA.event({
            category: 'Authentication',
            action: 'Logged out'
        });
        dispatch(setNavigationToLoginPage());
    };

    return (
        <Card raised={true}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Hello {user.name}!
                </Typography>
                <Typography component="div">
                    You are currently logged in as: {user.login}.  If this is not you, please log out.
                </Typography>
            </CardContent>
            <CardActions>
                <Box mx="auto">
                    <Button variant="outlined" onClick={handleLogout}>Logout</Button>
                </Box>
            </CardActions>
        </Card>
    );
}