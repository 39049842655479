import {createSlice} from '@reduxjs/toolkit'

const navigateSlice = createSlice({
    name: 'navigationStep',
    initialState: 0,
    reducers: {
        setNavigationToLoginPage() {
            return 0;
        },
        setNavigationToLandingPage() {
            return 1;
        },
        setNavigationToEnrollmentForm() {
            return 2;
        },
        setNavigationToWorkoutForm() {
            return 3;
        },
        setNavigationToGoalUpdater() {
            return 4;
        },
        setNavigationToClientAssessment() {
            return 5;
        },
        setNavigationToAdminLandingPage() {
            return 6;
        },
        setNavigationToClientDashboardPage() {
            return 7;
        }
    }
});

export const {setNavigationToLoginPage, setNavigationToLandingPage, setNavigationToEnrollmentForm, setNavigationToWorkoutForm, setNavigationToGoalUpdater, setNavigationToClientAssessment, setNavigationToAdminLandingPage, setNavigationToClientDashboardPage} = navigateSlice.actions;

export default navigateSlice.reducer