import React from 'react';
import {Chip} from "@material-ui/core";

export default function ExercisePrompt(props) {

    const shouldDisplay = () => {
        if (props.value) {
            return true;
        } else {
            return false;
        }
    }

    const determineLabel = () => {
        return props.label + ': ' + props.value;
    }

    return (
        <span>
            {shouldDisplay()  &&
                <Chip style={{marginRight: 4}} size="small" variant="outlined" label={determineLabel()} />
            }
        </span>
    );
}