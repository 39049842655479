import {createSlice} from '@reduxjs/toolkit'

const clientSlice = createSlice({
    name: 'client',
    initialState: null,
    reducers: {
        setClient(state, action) {
            return action.payload;
        },
        removeClient() {
            return null;
        }
    }
});

export const {setClient, removeClient} = clientSlice.actions;

export default clientSlice.reducer