import React from "react";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {storeEnrollmentParQQuestionnaire} from "../../../reducers/enrollmentDataSlice";
import {nextEnrollmentStep} from "../../../reducers/enrollmentStepSlice";
import EnrollmentNavigationButtons from "../buttons/EnrollmentNavigationButtons";
import ParQQuestionnaire from "./ParQQuestionnaire";
import Box from "@material-ui/core/Box";

export default function ParQSection(props) {

    const {control, register, errors, handleSubmit} = useForm();
    const dispatch = useDispatch();

    const onSubmit = data => {
        console.log('result of required forms:', JSON.stringify(data));
        dispatch(storeEnrollmentParQQuestionnaire(data));
        dispatch(nextEnrollmentStep());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <section style={!props.show ? {display: "none"} : {}}>
                <Box pt={2} maxWidth={800} mx="auto">
                    <ParQQuestionnaire errors={errors} register={register} control={control} />
                    <EnrollmentNavigationButtons/>
                </Box>
            </section>
        </form>
    )
}