import React from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import LoggedInAs from "./LoggedInAs";
import Logi from "./Logi";

export default function AdminLandingPage() {

    return (
        <Container>
            <Box m={2}>
                <Typography component="div" variant="h5" color="primary" align="center">
                    Valemee Workout Tracker Administration
                </Typography>
            </Box>
            <Box pt={2} mx="auto" maxWidth={600}>
                <LoggedInAs />
            </Box>
            <Box pt={2} mx="auto" maxWidth={600}>
                <Logi />
            </Box>

        </Container>
    );
}