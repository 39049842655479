import {createSlice} from '@reduxjs/toolkit'

const enrollmentStepSlice = createSlice({
    name: 'enrollmentStep',
    initialState: 0,
    reducers: {
        nextEnrollmentStep(state) {
            return state + 1;
        },
        previousEnrollmentStep(state) {
            return state - 1;
        },
        resetEnrollmentStep() {
            return 0;
        }
    }
});

export const {nextEnrollmentStep, previousEnrollmentStep, resetEnrollmentStep} = enrollmentStepSlice.actions;

export default enrollmentStepSlice.reducer