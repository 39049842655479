import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {Controller} from "react-hook-form";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {TextField} from "@material-ui/core";

export default function OccupationalQuestions(props) {

    const watchRecreationalActivitiesAnswer = props.watch('recreationalActivities', '');
    const watchHobbiesAnswer = props.watch('hobbies', '');

    return (
        <Grid container spacing={3} justify="flex-start" style={{paddingTop: 20}}>

            <Grid item xs={12}>
                <Typography component="div" variant="h5" color="secondary">Recreational Questions</Typography>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.recreationalActivities} component="fieldset">
                    <FormLabel component="legend">* Do you partake in any recreational activities (golf, tennis, skiing, etc.)?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="recreationalActivities"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="recreationalActivities"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            {watchRecreationalActivitiesAnswer === 'Yes' ?
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors.recreationalActivitiesDescription}
                                   name="recreationalActivitiesDescription"
                                   inputRef={props.register({required: true})}
                                   id="recreationalActivitiesDescription"
                                   label="* Description of Recreational Activities"
                                   variant="outlined"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                : ''}

            <Grid item xs={12}>
                <FormControl error={!!props.errors.hobbies} component="fieldset">
                    <FormLabel component="legend">* Do you have any hobbies (reading, gardening, working on cars, exploring the Internet, etc.)?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="hobbies"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="hobbies"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            {watchHobbiesAnswer === 'Yes' ?
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors.hobbiesDescription}
                                   name="hobbiesDescription"
                                   inputRef={props.register({required: true})}
                                   id="hobbiesDescription"
                                   label="* Description of Hobbies"
                                   variant="outlined"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                : ''}

        </Grid>
    )
}