import React from 'react';
import Label from "./Label";
import ExercisePrompt from "./ExercisePrompt";

export default function ExercisePrompts(props) {

    const exercise = props.exercise;

    return (
        <div>
            <Label label="Prompts: "/>
            <ExercisePrompt value={exercise.promptVisual} label="Visual"/>
            <ExercisePrompt value={exercise.promptVerbal} label="Verbal"/>
            <ExercisePrompt value={exercise.promptGestural} label="Gestural"/>
            <ExercisePrompt value={exercise.promptModeling} label="Modeling"/>
            <ExercisePrompt value={exercise.promptTactile} label="Tactile"/>
            <ExercisePrompt value={exercise.promptPhysical} label="Physical"/>
        </div>
    );
}